import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CAUserDto } from 'src/core/models/administration/user/CAUser.dto';
import { Observable, of, tap } from 'rxjs';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { ProfilePictureResultDto } from 'src/core/models/shared/profile-picture-result.dto';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { UserPhotoDto } from 'src/core/models/shared/user-photo.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  @Output()
  currentUserPhotoChanged = new EventEmitter();

  private cache: any = {};
  private USER_ID_LIST_LIMIT: number = 100;

  constructor(private http: HttpClient) {}

  getProfilePictureFromAbp(userId: string): Observable<ProfilePictureResultDto> {
    const url = `${environment.apis.AbpAccountPublic.url}/api/account/profile-picture/${userId}`;

    return this.http.get(url, {
      headers: { 'hide-errors': 'true' },
    }) as Observable<ProfilePictureResultDto>;
  }

  uploadProfile(file: FormData, userId: string) {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo';

    return this.http.post<any>(url, file, {
      reportProgress: true,
      observe: 'events',
    });
  }

  downloadProfile(userId: string): Observable<File> {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo';
    var req = this.http.get<File>(url, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
    if (!this.cache[userId]) {
      this.cache[userId] = req.pipe(shareReplay(1));
    }

    return this.cache[userId];
  }

  downloadProfileThumbnail(userId: string): Observable<File> {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo-thumbnail';
    var req = this.http.get<File>(url, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
    var key = userId + '-thumbnail';
    if (!this.cache[key]) {
      this.cache[key] = req.pipe(shareReplay(1));
    }

    return this.cache[key];
  }

  bulkDownloadProfileThumbnail(
    userIds: string[],
    filters: FilterItemDto[],
    filterCached: boolean = true
  ): Observable<UserPhotoDto[]> {
    var filteredUserIds = userIds;

    if (filterCached) {
      filteredUserIds = [];
      userIds.forEach(u => {
        var key = u + '-thumbnail';
        if (!this.cache[key]) {
          filteredUserIds.push(u);
        }
      });
    }

    let params = new HttpParams();
    var req = new Observable<UserPhotoDto[]>();

    if (filteredUserIds.length <= 0) {
      var empty: UserPhotoDto[] = [];
      req = of(empty);
    } else if (filteredUserIds.length <= this.USER_ID_LIST_LIMIT) {
      params = new HttpParams();
      params = params.append('userIds', JSON.stringify(filteredUserIds));

      const url = this.getApiUrl(UserPhotoDto) + '/bulk-photo-thumbnail';
      req = this.http.get<UserPhotoDto[]>(url, {
        params,
      }) as Observable<UserPhotoDto[]>;
    } else {
      params = params.append('filters', JSON.stringify(filters));
      params = params.append('sorters', JSON.stringify([]));
      params = params.append('skipCount', JSON.stringify(0));
      params = params.append('maxResultCount', JSON.stringify(99999));

      const url = this.getApiUrl(UserPhotoDto) + '/bulk-download-photo-thumbnail';
      req = this.http.get<UserPhotoDto[]>(url, {
        params,
      }) as Observable<UserPhotoDto[]>;
    }

    req = req.pipe(
      tap(res => {
        if (res) {
          res.forEach(up => {
            var key = up.userId + '-thumbnail';
            if (!this.cache[key]) {
              this.cache[key] = of(this.convertBase64ToBlob(up.photo)).pipe(shareReplay(1));
            }
          });
        }
      })
    );

    return req;
  }

  getProfileImageUrl(userId: string): string {
    return this.getApiUrl(CAUserDto) + '/' + userId + '/photo';
  }

  removeProfile(userId: string) {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo';

    return this.http.delete<any>(url);
  }

  protected getApiUrl(type = DtoHasApi): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/' + type.apiUrl : type.apiUrl;
  }

  private convertBase64ToBlob(input: string): Blob {
    const byteCharacters = atob(input);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: 'image/png' });
  }
}
