import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PhraseTypeDto } from 'src/core/models/conversation/phrase-type/phrase-type.dto';
import { NERTemplateDto } from 'src/core/models/conversation/phrase-type/ner-template.dto';
import { RedactionResponseDto } from 'src/core/models/conversation/phrase-type/redaction-response.dto';

@Injectable({
  providedIn: 'root',
})
export class PhraseTypeSettingsService {
  constructor(private config: ConfigStateService, private http: HttpClient) {}

  public get redactionEnabled(): boolean {
    return this.config.getSetting('Global.DynamicDataMaskingEnabled').toLowerCase() === 'true'
      ? true
      : false;
  }

  public get staticRedactionEnabled(): boolean {
    return this.config.getSetting('Global.DynamicDataMaskingEnabled').toLowerCase() == 'true' &&
      this.config.getSetting('PhraseType.RedactionType') == 'Conversation.RedactionType.Static'
      ? true
      : false;
  }

  public get dynamicRedactionEnabled(): boolean {
    return this.config.getSetting('Global.DynamicDataMaskingEnabled').toLowerCase() == 'true' &&
      this.config.getSetting('PhraseType.RedactionType') == 'Conversation.RedactionType.Dynamic'
      ? true
      : false;
  }

  public get performLLMWithMaskedDataEnabled(): boolean {
    return this.config.getSetting('Global.DynamicDataMaskingEnabled').toLowerCase() == 'true' &&
      this.config.getSetting('Redaction.PerformLLMWithMaskedDataEnabled').toLowerCase() === 'true'
      ? true
      : false;
  }

  getNERTemplates(): Observable<NERTemplateDto[]> {
    const url = this.getApiUrl() + '/recognition';
    return this.http.get(url) as Observable<NERTemplateDto[]>;
  }

  updateNEREnability(templateIds: number[]) {
    const url = this.getApiUrl() + '/recognition-enability';
    return this.http.put(url, templateIds);
  }

  getRedactionTemplates(): Observable<RedactionResponseDto> {
    const url = this.getApiUrl() + '/redaction';

    return this.http.get(url) as Observable<RedactionResponseDto>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/' + PhraseTypeDto.apiUrl : PhraseTypeDto.apiUrl;
  }
}
