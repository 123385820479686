import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.RedactionStatus',
})
export class ConversationRedactionStatus {
    @GenericLookup({
        code: 'Conversation.RedactionStatus.NotRedacted',
    })
    static notRedacted: number;

    @GenericLookup({
        code: 'Conversation.RedactionStatus.Redacting',
    })
    static redacting: number;

    @GenericLookup({
        code: 'Conversation.RedactionStatus.NotRequired',
    })
    static notRequired: number;

    @GenericLookup({
        code: 'Conversation.RedactionStatus.Skipped',
    })
    static skipped: number;

    @GenericLookup({
        code: 'Conversation.RedactionStatus.Redacted',
    })
    static redacted: number;

    @GenericLookup({
        code: 'Conversation.RedactionStatus.Failed',
    })
    static failed: number;
}