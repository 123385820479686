<div>
  <h2>{{ 'Settings::Redaction' | abpLocalization }}</h2>
</div>
<br />
<form [formGroup]="settingsForm" (ngSubmit)="onSubmitSettings()">
  <input
    type="checkbox"
    id="redactionEnabled"
    formControlName="redactionEnabled"
    (change)="onRedactionEnabledChanged($event)" />
  <label class="ps-1" for="redactionEnabled">
    <h4>{{ 'Settings::RedactionEnabled' | abpLocalization }}</h4>
  </label>
  <div *ngIf="settingsForm.get('redactionEnabled').value">
    <div class="form-group ms-4">
      <div *ngFor="let type of redactionTypes; let i = index">
        <div class="display-inline">
          <input
            id="radio{{ type.id }}"
            type="radio"
            class="radio-button"
            formControlName="redactionType"
            (change)="onRedactionTypeChange($event)"
            [value]="type.code" />
          <label for="radio{{ type.id }}" class="ps-1 pt-2 pb-0">
            <h5>{{ 'GenericLookup::' + type.code | abpLocalization }}</h5>
          </label>
        </div>
        <small class="form-text text-muted ms-4 pt-0">
          {{ 'Settings::' + type.name + 'Info' | abpLocalization }}
        </small>
        <div *ngIf="i === 0">
          <div class="display-inline ms-4 mt-2">
            <input
              id="performLLMWithMaskedDataEnabled"
              type="checkbox"
              formControlName="performLLMWithMaskedDataEnabled"
              (change)="onPerformLLMWithMaskedDataEnabledChange($event)"
              [(ngModel)]="performLLMWithMaskedDataEnabled" />
            <label for="performLLMWithMaskedDataEnabled" class="ps-2">
              {{ 'Settings::Global.PerformOperations' | abpLocalization }}
            </label>
          </div>
          <small class="form-text text-muted ms-4 pt-0" style="margin-left: 53px !important;">
            {{ 'Settings::Global.PerformOperationsInfo' | abpLocalization }}
          </small>
        </div>
      </div>
    </div>
    <div class="form-group mt-4 selector-class">
      <div class="fw-bold">
        <h4>{{ getSelectedCountText() }}</h4>
      </div>
      <ng-container *ngIf="loading">
        <i class="far fa-spinner fa-spin"></i>
        <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span>
      </ng-container>
      <ng-container *ngIf="!loading">
        <div *ngIf="phraseTypeList?.length">
          <div class="entity-container">
            <ng-container *ngFor="let group of groupByList | keyvalue">
              <div class="w-100 fw-bold">
                {{ group.key }}
              </div>
              <div
                *ngFor="let phraseType of group.value"
                class="entity-column"
                [title]="
                  phraseType.isUsedInRecognition
                    ? ('Settings::EntityUseInRecognition' | abpLocalization)
                    : ''
                ">
                <div class="entity-column-label" [disabled]="phraseType.isUsedInRecognition">
                  <div class="display-inline">
                    <input
                      id="phrase-type-{{ phraseType.id }}"
                      type="checkbox"
                      [checked]="isSelected(phraseType)"
                      [disabled]="phraseType.isUsedInRecognition"
                      (change)="onEntityChange($event, phraseType)" />
                    <label for="phrase-type-{{ phraseType.id }}">
                      {{ phraseType.displayName }}
                      <small class="form-text text-muted ps-1">
                        {{ phraseType.supportedLanguages }}
                      </small>
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="form-group mt-4">
      <h4>{{ 'Settings::RedactionMode' | abpLocalization }}</h4>
      <div *ngFor="let mode of redactionModes" class="ms-4">
        <div class="display-inline">
          <input
            id="radio{{ mode.id }}"
            type="radio"
            class="radio-button"
            formControlName="redactionMode"
            [value]="mode.code" />
          <label for="radio{{ mode.id }}" class="ps-1 pt-2 pb-0">
            <h5>{{ 'GenericLookup::' + mode.code | abpLocalization }}</h5>
          </label>
        </div>
        <small class="form-text text-muted ms-4">
          {{ 'Settings::' + mode.name + 'Info' | abpLocalization }}
        </small>
      </div>
    </div>
  </div>
  <div>
    <button type="submit" class="btn btn-primary mt-4 mb-4" [disabled]="disableSaveButton">
      <i class="me-1 fas fa-save"></i>
      {{ 'AbpUi::Save' | abpLocalization }}
    </button>
  </div>
</form>
